.videoGallery {
  margin-top: 40px;
  width: 100%;
}
.videoGallery iframe {
  width: 100%;
  height: 350px;
}
.videoGalleryItem {
  padding-bottom: 30px;
}

@media screen and (max-width: 768px) {
  .videoGallery iframe {
    width: 100%;
    height: 200px;
  }
}
