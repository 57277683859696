@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700");

body {
  color: #262626;
  font-size: 16px;
  line-height: 1.8;
  font-family: "Montserrat", sans-serif;
  width: 100%;
}

ul {
  margin: 0px;
  padding: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
a {
  color: #262626;
  text-decoration: none;
}

a:visited {
  color: #262626;
}

a:hover,
a:focus,
a:active {
  color: #ff5a00;
}

a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  outline: 0;
}
.btn {
  padding: 18px 40px;
  border: 2px solid #262626;
  border-radius: 24px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: #262626;
  outline: none;
}

.btn:focus {
  outline: none;
}

.btn.orange-border {
  border-color: #ff5a00;
}

.btn.orange-border:hover,
.btn.gradient-bg {
  border-color: transparent;
  background: -moz-linear-gradient(
    180deg,
    rgba(255, 90, 0, 1) 0%,
    rgba(255, 54, 0, 1) 100%
  ); /* ff3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(255, 90, 0, 1)),
    color-stop(100%, rgba(1255, 54, 0, 1))
  ); /* safari4+,chrome */
  background: -webkit-linear-gradient(
    180deg,
    rgba(255, 90, 0, 1) 0%,
    rgba(255, 54, 0, 1) 100%
  ); /* safari5.1+,chrome10+ */
  background: -o-linear-gradient(
    180deg,
    rgba(255, 90, 0, 1) 0%,
    rgba(255, 54, 0, 1) 100%
  ); /* opera 11.10+ */
  background: -ms-linear-gradient(
    180deg,
    rgba(255, 90, 0, 1) 0%,
    rgba(255, 54, 0, 1) 100%
  ); /* ie10+ */
  background: linear-gradient(
    270deg,
    rgba(255, 90, 0, 1) 0%,
    rgba(255, 54, 0, 1) 100%
  ); /* w3c */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff5a00', endColorstr='#ff3600',GradientType=1 ); /* ie6-9 */
  color: #fff;
}

.sigProPrintMessage {
  display: none;
}

.sp-designation {
  min-height: 60px;
}

.members_table h3 {
  font-size: 1rem;
  font-weight: bold;
  min-height: 40px;
}