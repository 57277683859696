.home-page-limestone {
  padding: 96px 0;
}

.home-page-limestone .section-heading .entry-title {
  padding-bottom: 36px;
  line-height: 1.6;
}

.home-page-limestone .section-heading p {
  font-size: 14px;
  color: #595858;
}
