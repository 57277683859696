.pagination {
  margin-top: 92px;
}

.pagination a {
  margin-right: 16px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  color: #262626;
  text-decoration: navajowhite;
}

.pagination a:hover,
.pagination .active a {
  color: #ff3900;
}

/*--------------------------------------------------------------
  # Sidebar
  --------------------------------------------------------------*/
@media screen and (max-width: 992px) {
  .sidebar {
    margin-top: 96px;
  }
}

.sidebar h2 {
  font-size: 18px;
  font-weight: 600;
}

/*
  # Search
  --------------------------------*/
.search-widget input[type="search"] {
  width: calc(100% - 76px);
  height: 52px;
  padding: 10px 20px;
  border: 1px solid #e0e0e0;
  font-size: 14px;
  outline: none;
}

.search-widget input[type="search"]::placeholder {
  color: #929191;
}

.search-widget button[type="submit"] {
  width: 75px;
  height: 54px;
  border: 0;
  margin-left: -5px;
  font-size: 14px;
  font-weight: 500;
  background: #ff5a00;
  color: #fff;
  cursor: pointer;
  outline: none;
}

/*
  # Popular Posts
  --------------------------------*/
.popular-posts {
  margin-top: 0;
}

.popular-posts ul {
  list-style: none;
  margin: 0;
}

.popular-posts li {
  margin-bottom: 15px;
}

.popular-posts li:nth-last-of-type(1) {
  margin-bottom: 0;
}

.popular-posts figure,
.popular-posts figure a,
.popular-posts figure img {
  display: block;
  margin: 0;
}

.popular-posts figure {
  width: 72px;
}

.popular-posts .entry-content {
  width: calc(100% - 100px);
}

.popular-posts h3 {
  font-size: 14px;
  font-weight: 500;
}

.popular-posts h3 a {
  color: #262626;
}

.popular-posts .posted-date {
  font-size: 12px;
  color: #ff5a00;
}
.sidebar img {
    width: 100%;
    display: block;
}

/*
  # Upcoming Events
  --------------------------------*/
.sidebar .upcoming-events {
  margin-top: 60px;
}

.sidebar .upcoming-events ul {
  list-style: none;
  margin: 60px 0 0;
}

.sidebar .upcoming-events li {
  margin-bottom: 32px;
}

.sidebar .upcoming-events li:nth-last-of-type(1) {
  margin-bottom: 0;
}

.sidebar .upcoming-events figure,
.sidebar .upcoming-events figure a,
.sidebar .upcoming-events figure img {
  display: block;
  margin: 0;
}

.sidebar .upcoming-events figure {
  width: 140px;
  /*margin-right: 24px;*/
}

.sidebar .upcoming-events .entry-content {
  width: calc(100% - 140px);
}

.sidebar .upcoming-events h3 {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 500;
}

.sidebar .upcoming-events h3 a {
  color: #262626;
}

.sidebar .upcoming-events .post-metas {
  font-size: 11px;
  line-height: 1;
  color: #262626;
}

.sidebar .upcoming-events .post-metas a {
  color: #262626;
}

.sidebar .upcoming-events .post-metas > span {
  display: block;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #262626;
}

.sidebar .upcoming-events .post-metas > span:nth-last-of-type(1) {
  border: 0;
  padding-right: 0;
  margin-right: 0;
}

.sidebar .upcoming-events p {
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 14px;
  color: #929191;
}

/*
  # Featured Cause
  --------------------------------*/
.sidebar .featured-cause {
  margin-top: 60px;
}

.sidebar .featured-cause .cause-wrap {
  padding: 0;
  background: none;
}

.sidebar .featured-cause .featured-cause .cause-wrap figure {
  width: 100%;
}

.sidebar .cause-wrap figure img {
  display: block;
  width: 100%;
}

.sidebar .featured-cause .cause-content-wrap {
  width: 100%;
  padding: 32px;
  margin-top: 0;
  border: 1px solid #e0e0e0;
  border-top: 0;
}
