.home-page-welcome {
  position: relative;
  padding: 96px 0;
  /* background: url("images/welcome-bg.jpg") no-repeat center; */
  background-size: cover;
  z-index: 99;
}

.home-page-welcome::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  background: rgba(21, 21, 21, 0.9);
}

.welcome-content .entry-title {
  position: relative;
  padding-bottom: 24px;
  font-size: 36px;
  font-weight: 600;
  color: #fff;
}

.welcome-content .entry-title::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 64px;
  height: 4px;
  border-radius: 2px;
  background: #ff5a00;
}

.welcome-content .entry-content {
  font-size: 14px;
  line-height: 2;
  color: #b7b7b7;
}

.home-page-welcome img {
  display: block;
  width: 100%;
}

@media screen and (max-width: 992px) {
  .home-page-welcome img {
    margin-bottom: 60px;
  }
}
