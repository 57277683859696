.home-page-icon-boxes {
  padding: 96px 0;
}
.cause-content-wrap .entry-title a {
  font-size: 18px;
  font-weight: 600;
  color: #262626;
}
.cause-content-wrap .entry-content {
  margin-top: 28px;
  margin-bottom: 0;
  font-size: 14px;
  color: #929191;
}

.cause-content-wrap .posted-date a,
.cause-content-wrap .cats-links a {
  display: block;
  position: relative;
  margin-top: 6px;
  font-size: 11px;
  font-weight: 500;
  line-height: 1;
  color: #262626;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}
