.languagePicker {
  display: flex;
  align-items: center;
}

.languagePicker .flag {
  padding: 0 5px;
}

.languagePicker select {
  width: 50px;
  text-align: center;
  height: 33px;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  border: none;
  background-color: #952528;
  color: #fff;
  font-weight: 600;
}

@media screen and (max-width: 1000px) {
  .languagePicker {
    justify-content: center;
    padding-top: 15px;
  }
}
