/*
# Header Bar
--------------------------------*/

.sticky-bar {
  left: 0 !important;
  margin: auto;
  position: fixed !important;
  top: 0 !important;
  width: 100%;
  -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  z-index: 999 !important;
  -webkit-animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 300ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  background-color: #fff;
  transition: all 0.4s;
}
.shadow-nav {
  box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  margin-bottom: 50px;
}

.sticky-bar li a { 
  color: #262626!important;
}

.sticky-bar li { 
  color: #262626!important;
}

.site-navigation ul li a,
.sticky-bar .site-navigation ul li a {
  display: block;
  padding: 16px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: #262626;
  text-decoration: none;
  border-bottom: 3px solid transparent;
}
.block {
  position: relative;
  overflow: visible;
}

.sub-menu-block {
  width: 100%;
  min-width: 250px;
  position: absolute !important;
  color: red;
  background: #ff5a00;
  border-radius: 15px;
  box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);

  display: none;
  /* border: 2px solid red; */
}
.submenuMobile {
  width: 100%;
  min-width: 250px;
  position: absolute !important;
  color: red;
  background: #ff5a00;
  border-radius: 15px;
  box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);

  display: none;
}

.sidebarOpen {
  display: block;
  padding: 15px;
  z-index: 999;
}

.arrow {
  position: absolute;
  right: -14px;
  top: 10px;
  cursor: pointer;
  width: 40px;
  text-align: center;
}
.sidebarOpen a {
  color: white !important;
  display: inline-block !important;
}

.sub-menu-block a {
  color: white !important;
  display: inline-block !important;
}

.sticky-bar .sub-menu-block {
  width: 200px;
  min-width: 250px;
  position: absolute !important;
  color: red;
  background-color: white;
  display: none;
  /* border: 2px solid red; */
}
.sticky-bar .sub-menu-block a {
  color: black !important;
}
.shadow-nav .sub-menu-block {
  width: 200px;
  min-width: 250px;
  position: absolute !important;
  color: red;
  background-color: white;
  display: none;
  /* border: 2px solid red; */
}
.shadow-nav .sub-menu-block a {
  color: black !important;
}

@media screen and (max-width: 768px) {
  #someid:hover .sub-menu-block {
    display: none;
  }
}

.block:hover .sub-menu-block {
  display: block;
}

@media screen and (min-width: 1301px) {
  .site-header {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 8;
    padding: 0px 30px;
  }
  .home-header {
    position: relative;
  }
}

.top-header-bar {
  background: #ff4800;
  color: #fff;
}

/*
# Header Bar Email
--------------------------------*/
.header-bar-email,
.header-bar-text {
  padding: 20px 0;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
}

.header-bar-email a,
.header-bar-text span {
  text-transform: lowercase;
  color: #fff;
  text-decoration: none;
}

/*
# Header Bar Text
--------------------------------*/
.header-bar-text {
  margin-left: 48px;
}

.header-bar-text p {
  margin: 0;
}

/*
# Header Bar Donate Button
--------------------------------*/
.donate-btn a {
  display: inline-block;
  padding: 20px 40px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  text-decoration: navajowhite;
  background: #262222;
}

/*
# Site Branding
--------------------------------*/
.site-branding {
  width: calc(100% - 24px);
  padding: 20px 0;
}

.site-branding a {
  margin-top: 6px;
  outline: none;
}

@media screen and (min-width: 1301px) {
  .site-branding {
    width: auto;
  }
}

@media screen and (max-width: 1301px) {
  .site-branding a img {
    height: 100px;
  }
}

/*
  Hamburger Menu
----------------------------------------*/
.hamburger-menu {
  position: relative;
  width: 24px;
  height: 22px;
  margin: 0 auto;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.hamburger-menu span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #262626;
  border-radius: 10px;
  opacity: 1;
  left: 0;
  transition: 0.25s ease-in-out;
}

.hamburger-menu span:nth-child(1) {
  top: 2px;
}

.hamburger-menu span:nth-child(2),
.hamburger-menu span:nth-child(3) {
  top: 10px;
}

.hamburger-menu span:nth-child(4) {
  top: 18px;
}

.hamburger-menu.open span:nth-child(1) {
  top: 18px;
  width: 0;
  left: 50%;
}

.hamburger-menu.open span:nth-child(2) {
  transform: rotate(45deg);
}

.hamburger-menu.open span:nth-child(3) {
  transform: rotate(-45deg);
}

.hamburger-menu.open span:nth-child(4) {
  top: 18px;
  width: 0;
  left: 50%;
}

/*
# Main Menu
--------------------------------*/
.site-navigation ul {
  position: fixed;
  top: 0;
  left: -320px;
  z-index: 9999;
  width: 320px;
  max-width: calc(100% - 120px);
  height: 100%;
  padding: 30px 15px;
  margin: 0;
  background: #fff;
  list-style: none;
  overflow-x: scroll;
  transition: all 0.35s;
}

.site-navigation.show ul {
  left: 0;
}

.site-navigation ul li {
  margin: 0 8px;
}
.site-navigation ul li a {
  display: block;
  padding: 16px 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: #262626;
  text-decoration: none;
  transition: color 0.35s;
  outline: none;
}

.site-navigation ul li a .btn {
  padding: 18px 40px !important;
}
.submenu {
  display: none;
  position: relative;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
}
.menu-item:hover > .submenu {
  display: block;
}
.home-header .logo {
  width: 90px;
}

@media screen and (min-width: 1301px) {
  .site-navigation ul {
    position: relative;
    top: auto;
    left: auto;
    width: 100%;
    max-width: 100%;
    height: auto;
    padding: 0;
    overflow: auto;
    background: transparent;
  }

  .site-navigation ul li a {
    padding: 49px 0 30px;
    margin-left: 20px;
  }
  .sticky-bar .site-navigation ul li.current-menu-item a {
    color: #000000 !important;
    border-bottom: 3px solid #000000 !important;
  }

  .sticky-bar .site-navigation ul li a:hover,
  .sticky-bar .site-navigation ul li.current-menu-item a {
    color: #000000 !important;
    border-bottom: 3px solid #000000 !important;
  }

  .sticky-bar .logo {
    width: 90px;
  }

  .site-navigation ul li a:hover,
  .site-navigation ul li.current-menu-item a {
    border-bottom: 3px solid #ffffff !important;
    color: #ffffff !important;
  }

  .site-navigation ul li a {
    display: block;
    padding: 16px 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
    color: #ffffff;
    text-decoration: none;
    transition: color 0.35s;
  }
}

.site-navigation ul li a:hover,
.site-navigation ul li.current-menu-item a {
  border-bottom: 3px solid #ff5a00;
  color: #ff5a00;
}
.home-header ul li a:hover,
.home-header ul li.current-menu-item a {
  border-bottom: 3px solid #000000 !important;
  color: #000000 !important;
}
.home-header ul li a {
  color: #000000;
}

/* submenu */
.submenu {
  display: none;
  position: absolute;
  top: 200px;
  z-index: 99999;
}
.menu-item .submenu {
  display: block;
}

/*  */

/*
# Menu SHopping Cart
--------------------------------*/
.header-bar-cart {
  margin-left: 40px;
}

.header-bar-cart a {
  width: 48px;
  height: 100%;
}

.header-bar-cart a {
  color: #fff;
  text-decoration: none;
}

.header-top .header-info-right .header-social li {
  display: inline-block;
  padding-left: 19px;
  padding-top: 20px;
}

.header-top .header-info-right .header-social li a {
  color: #fff;
  font-size: 16px;
  text-decoration: none;
}

.header-top .header-info-left ul li {
  color: #fff;
  display: inline-block;
  margin-right: 15px;
  font-size: 14px;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  padding-right: 15px;
  text-decoration: none;
}

.header-top .header-info-left ul li a {
  text-decoration: none;
}

.search_button {
  padding: 0 15px;
}

.search-box {
  display: none;
  position: absolute;
  margin-top: 100px;
  margin-right: 180px;
  transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.search-box.opened {
  display: block;
}

.search-box input {
  width: 250px;
  padding-left: 10px;
  border-radius: 15px;
}