.footer-widgets {
  position: relative;
  padding: 96px 0 90px;
  font-weight: 500;
  color: #929191;
  /* background: url("images/foot-bg.jpg") no-repeat center; */
  background-size: cover;
  z-index: 99;
}

.footer-widgets::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 101%;
  background: rgba(22, 22, 22, 0.92);
}

.footer-widgets h2 {
  margin-bottom: 40px;
  font-size: 18px;
  color: #fff;
}

.footer-widgets ul {
  padding: 12px 0 0;
  margin: 0;
  list-style: none;
}

.footer-widgets ul li {
  margin-bottom: 8px;
  font-size: 14px;
}

.footer-widgets ul li a {
  color: #a2a1a1;
  text-decoration: none;
  outline: none;
}

.footer-widgets ul li a:hover {
  color: #ff5a00;
}

.foot-about p {
  font-size: 14px;
  line-height: 2;
  color: #929191;
}

.foot-about ul li {
  margin-right: 15px;
}

.foot-about ul li a {
  color: #fff;
}

.foot-latest-news ul li {
  display: block;
  margin-bottom: 36px;
}

.foot-latest-news ul li h3 {
  font-size: 14px;
}

.foot-latest-news ul li .posted-date {
  font-size: 12px;
  color: #ff5a00;
}

.foot-contact ul li {
  display: flex;
  align-items: baseline;
  margin-bottom: 15px;
}

.foot-contact ul li .fa {
  width: 28px;
  margin-right: 10px;
  font-size: 18px;
  text-align: center;
  color: #ff5a00;
}

.foot-contact ul li span {
  width: calc(100% - 3px);
}

.footer-bar {
  padding: 30px 0;
  font-size: 12px;
  color: #7b7b7b;
  background: #131212;
  text-align: center;
}
.footer-icon {
  width: 28px;
  margin-right: 10px;
  font-size: 18px;
  text-align: center;
  color: #ff5a00;
}
.footer-social svg {
  width: 25px;
  height: 18px;
  margin: 0;
}
