input {
    border: 1px solid #ccc;
    font-size: 12px;
    height: 30px;
    padding: 4px 8px;
    position: absolute;
    width: 50%;
    &:focus {
      outline: none;
    }
  }
  button {
    text-align: center;
    &:focus {
      outline: none;
    }
    &.btn-search,
    &.btn-reset {
      background: linear-gradient(270deg, rgba(255, 90, 0, 1) 0%, rgba(255, 54, 0, 1) 100%);
      border: none;
      height: 30px;
      font-size: 12px;
      padding: 4px;
      position: absolute;
      width: 30px;
    }
  }
  
  .sample {
    float: left;
    height: 20px;
    margin: 0 8%;
    position: relative;
    width: 34%;
    &.one,
    &.two {
      input {
        border-radius: 15px;
        right: 0;
        transition: all .3s ease-in-out;
        width: 250px;
        &:focus {
          width: 500px;
          ~ button {
            &.btn-search {
                background: linear-gradient(270deg, rgba(255, 90, 0, 1) 0%, rgba(255, 54, 0, 1) 100%);
                color: #fff;
            }
            &.btn-reset {
              right: -22px;
            }
          }
        }
      }
      button {
        transition: all .3s ease-in-out;
        &.btn-search {
          background: #ccc;
          border-radius: 50%;
          height: 26px;
          right: 2px;
          top: 2px;
          transition: all .3s ease-in-out;
          width: 26px;
        }
        &.btn-reset {
          background: #fff;
          border: 1px solid #ccc;
          border-radius: 50%;
          font-size: 10px;
          height: 20px;
          line-height: 20px;
          padding: 0;
          right: 5px;
          top: 5px;
          width: 20px;
          z-index: -1;
        }
      }
    }
    &.three,
    &.four {
      perspective: 400px;
      input {
        width: 120px;
        &:focus {
          ~ button {
            &.btn-search {
              transform: rotateY(180deg);
              transition: transform .6s ease-in-out .2s;
            }
            &.btn-reset {
              transform: rotateX(0deg) translateY(32px) translateX(2px);
              transition: transform .6s ease-in-out .8s;
            }
          }
        }
      }
      button {
        &.btn-search {
          backface-visibility: visible;
          color: #fff;
          padding: 0;
          position: relative;
          transform: rotateY(0deg);
          transform-origin: 121px 0;
          transform-style: preserve3d;
          transition: transform .6s ease-in-out .2s;
          width: 120px;
        }
        &.btn-reset {
          backface-visibility: hidden;
          background: #ccc;
          transform: rotateX(-180deg) translateY(30px) translateX(2px);
          transform-origin: 0 32px;
          transform-style: preserve3d;
          transition: transform .6s ease-in-out .2s;
          width: 120px;
        }
      }
    }
    &.four {
      button {
        &.btn-search {
          transition: transform .6s ease-in-out .8s;
        }
      }
    }
    &.five,
    &.six {
      perspective: 400px;
      input {
        width: 120px;
        &:focus {
          ~ button {
            &.btn-search {
              transform: rotateY(180deg) translateX(60px);
              transition: all .6s ease-in-out .2s;
              width: 60px;
            }
            &.btn-reset {
              transform: rotateY(0deg);
              transition: all .6s ease-in-out .8s;
            }
          }
        }
      }
      button {
        &.btn-search {
          backface-visibility: visible;
          color: #fff;
          padding: 0;
          position: relative;
          transform: rotateY(0deg) translateX(0px);
          transform-origin: 121px 0;
          transform-style: preserve3d;
          transition: all .6s ease-in-out .2s;
          width: 120px;
        }
        &.btn-reset {
          backface-visibility: hidden;
          background: #ccc;
          left: 184px;
          transform: rotateY(180deg);
          transform-origin: left 0;
          transform-style: preserve3d;
          transition: all .6s ease-in-out .2s;
          width: 60px;
        }
      }
    }
    &.six {
      button {
        &.btn-search {
          transition: all .6s ease-in-out .8s;
        }
      }
    }
    &.seven,
    &.eight {
      input {
        border-right: none;
        transition: all .3s ease-in;
        width: 120px;
        &:focus {
          width: 220px;
          ~ button {
            &.btn-search {
              background: hotpink;
              border-radius: 0 50% 50% 0;
              color: #fff;
              left: 220px;
            }
            &.btn-reset {
              animation: bounceRight .6s;
              animation-delay: .2s;
              animation-timing-function: cubic-bezier(.3,.2,1,.8);
              border-radius: 50%;
              color: #fff;
              left: 254px;
            }
          }
        }
      }
      button {
        &.btn-search {
          background: #ccc;
          left: 120px;
          transition: all .3s ease-in;
        }
        &.btn-reset {
          background: #000;
          height: 20px;
          left: 120px;
          top: 5px;
          transition: all .3s ease-in;
          width: 20px;
          z-index: -1;
        }
      }
    }
    &.nine,
    &.ten {
      input {
        border-radius: 15px;
        transition: all .6s ease-in-out .3s;
        width: 120px;
        &:focus {
          transition-delay: 0;
          width: 200px;
          ~ button {
            transform: rotateZ(360deg);
            &.btn-search {
              background: hotpink;
              color: #fff;
              left: 172px;
              transition-delay: 0;
            }
            &.btn-reset {
              left: 202px;
              transition-delay: .3s;
            }
          }
        }
      }
      button {
        transition: all .6s ease-in-out;
        &.btn-search {
          background: #ccc;
          border-radius: 50%;
          height: 26px;
          left: 92px;
          top: 2px;
          transition-delay: .3s;
          width: 26px;
        }
        &.btn-reset {
          background: #fff;
          border: 1px solid #ccc;
          border-radius: 50%;
          font-size: 10px;
          height: 20px;
          left: 92px;
          line-height: 20px;
          padding: 0;
          top: 5px;
          width: 20px;
          z-index: -1;
        }
      }
    }
    &.eleven,
    &.twelve {
      input {
        border-radius: 15px;
        transition: all .6s ease-in-out;
        width: 120px;
        &:focus {
          width: 200px;
          ~ button {
            &.btn-search {
              animation: jumpTowardSearch 1.2s linear;
              background: hotpink;
              color: #fff;
              left: 172px;
            }
            &.btn-reset {
              animation: jumpTowardReset 1.2s linear .4s;
              left: 202px;
            }
          }
        }
      }
      button {
        transition: all .6s ease-in-out;
        &.btn-search {
          background: #ccc;
          border-radius: 50%;
          height: 26px;
          left: 92px;
          top: 2px;
          width: 26px;
        }
        &.btn-reset {
          background: #fff;
          border: 1px solid #ccc;
          border-radius: 50%;
          font-size: 10px;
          height: 20px;
          left: 92px;
          line-height: 20px;
          padding: 0;
          top: 5px;
          width: 20px;
          z-index: -1;
        }
      }
    }
    &.thirteen,
    &.fourteen {
      input {
        background: none;
        border-color: #000;
        border-radius: 15px;
        border-width: 0 0 1px;
        transition: all .8s ease-in-out;
        width: 30px;
        &:focus {
          background: radial-gradient(ellipse at top left, transparent 65%, hotpink 140%);
          border-radius: 0 15px 15px 0;
          width: 250px;
          ~ button {
            &.btn-search {
              background: hotpink;
              color: #fff;
              left: 222px;
              transform: rotate(720deg);
            }
            &.btn-reset {
              left: 256px;
              transform: rotate(360deg);
            }
          }
        }
      }
      button {
        transition: all .8s ease-in-out;
        &.btn-search {
          background: #ccc;
          border: 1px solid #000;
          border-radius: 50%;
          height: 30px;
          left: 0;
          width: 30px;
        }
        &.btn-reset {
          background: #fff;
          border: 1px solid #ccc;
          border-radius: 50%;
          font-size: 10px;
          height: 20px;
          left: 2px;
          line-height: 20px;
          padding: 0;
          top: 10px;
          width: 20px;
          z-index: -1;
        }
      }
    }
  }
  @keyframes bounceRight {
    0% {
      transform: translateX(0px);
    }
    50% {
      transform: translateX(10px);
    }
    100% {
      transform: translateX(0px);
    }
  }
  @keyframes jumpTowardSearch {
    0% {
      background: #ccc;
      opacity: 1;
      transform: rotateZ(0deg) scale(1);
    }
    20% {
      background: #ccc;
      opacity: 0;
      transform: rotateZ(-60deg) scale(50);
    }
    55% {
      background: hotpink;
      opacity: 0;
      transform: rotateZ(-30deg) scale(100);
    }
    90% {
      background: hotpink;
      opacity: 0;
      transform: rotateZ(-30deg) scale(50);
    }
    100% {
      background: hotpink;
      opacity: 1;
      transform: rotateZ(0deg) scale(1);
    }
  }
  @keyframes jumpTowardReset {
    0% {
      opacity: 0;
      transform: rotateZ(0deg) scale(1);
    }
    20% {
      opacity: 0;
      transform: rotateZ(-60deg) scale(50);
    }
    55% {
      opacity: 0;
      transform: rotateZ(-30deg) scale(100);
    }
    90% {
      opacity: 0;
      transform: rotateZ(-30deg) scale(50);
    }
    100% {
      opacity: 1;
      transform: rotateZ(0deg) scale(1);
    }
  }