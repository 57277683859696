.pswp-gallery {
  width: 100%;
}
.pswp-gallery a img {
  padding-right: 10px;
  padding-bottom: 10px;
  object-fit: cover;
  width: 235px;
  height: 150px;
}
