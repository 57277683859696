/* @import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"; */

#container {
    margin: 1rem;
  }
  
  .items {
    margin-bottom: 1rem;
  }
  .news-wrap {
    padding: 96px 0;
  }
  
  .news-content {
    margin-bottom: 0px;
  }
  
  .news-content:nth-last-of-type(1) {
    margin-bottom: 0;
  }
  
  .news-content img {
    display: block;
    width: 100%;
    margin: 0;
  }
  
  .news-content .entry-header {
    margin-top: 0;
  }
  
  .news-content .entry-header .header-elements,
  .news-content .donate-icon {
    margin-top: 30px;
  }
  
  .news-content .entry-title {
    font-size: 24px;
    font-weight: 500;
  }
  
  .news-content .entry-title a {
    color: #262626;
    text-decoration: none;
  }
  
  .news-category {
    background: #ffffff;
    margin: 4px 0;
    padding: 15px;
    margin-bottom: 20px;
    box-shadow: 0 5px 5px rgba(25, 25, 25, 0.1);
  }
  
  .news-content .posted-date {
    font-size: 14px;
    font-weight: 500;
    color: #ff5a00;
  }
  
  .post-metas {
    font-size: 12px;
    line-height: 1;
    color: #929191;
  }
  
  .post-metas a {
    color: #929191;
  }
  
  .post-metas > span {
    display: block;
    padding-right: 10px;
    margin-top: 6px;
    margin-right: 10px;
    border-right: 1px solid #929191;
  }
  
  .post-metas > span:nth-last-of-type(1) {
    border: 0;
    padding-right: 0;
    margin-right: 0;
  }
  .donate-icon a {
    width: 60px;
    height: 60px;
  }
  
  .news-content .entry-content {
    font-size: 14px;
    color: #929191;
  }
  
  .entry-content img {
    max-width: 100%;
  }
  
  .news-block {
    height: 140px;
    overflow: hidden;
  }
  
  .news-block img {
    display: none;
  }
  
  .news-content .entry-footer {
    margin-top: 0;
  }
  
  ul[aria-label="Pagination"] {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  ul[aria-label="Pagination"] li {
    display: inline;
    color: #ff5a00;
    outline: none;
  }
  ul[aria-label="Pagination"] li a {
    color: #ff5a00;
    outline: none;
  }
  ul[aria-label="Pagination"] .selected {
    border-bottom: 2px solid#ff5a00;
  }
  