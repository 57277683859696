.singleNewsWrapper .entry-title p {
  font-size: 18px;
  font-weight: 600;
  color: #262626;
}
/* .singleNewsWrapper .entry-content {
  padding: 15px;
} */

.posted-date p {
  padding-right: 14px;
  margin-right: 14px;
}

.news-image img {
  height: 400px;
  object-fit: cover;
}

.singleNewsWrapper .posted-date p::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 1px;
  height: 100%;
  background: #262626;
}
.galleryBlock {
  margin-top: 30px;
  margin-bottom: 30px;
  gap: 20px;
}
.galleryBlock img {
  gap: 20px;
}
.singleNewsWrapper .singleNewsTitle {
  display: flex;
  width: 100%;

  flex-direction: column;
  padding-top: 20px;
}
